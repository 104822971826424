import React, { useState } from "react";
import {
  BedrockRuntimeClient,
  InvokeModelCommand,
} from "@aws-sdk/client-bedrock-runtime";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { uploadData } from "@aws-amplify/storage";
import { generateClient } from "@aws-amplify/api";
import {
  Text,
  Stack,
  Paper,
  Title,
  Flex,
  ScrollArea,
  FileInput,
  Button,
  Image,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import awsconfig from "../../aws-exports";
import * as mutations from "../../graphql/mutations";

Amplify.configure(awsconfig);

const short_prompt = `Analyze the provided medical bill image and explain each CPT code in one short sentence. Also identify any errors such as duplicates or unbundling. 
Respond ONLY with a JSON array where each item has 4 properties: 
"CPT Code", "Procedure", "Error", and "Error Type". For example:
[
  {
    "CPT Code": "99213",
    "Procedure": "Office visit",
    "CPT Code Explanation": "Level three office visit with an established patient typically 20 minutes or longer.",
    "Error": "Duplicate charge",
    "Error Type": "Billing the same service twice"
  }
]
Do not include any explanatory text before or after the JSON array.`;

function Step2({ setBillAnalysis }) {
  const [response, setResponse] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [billName, setBillName] = useState("");

  const callClaude = async () => {
    if (!billName) {
      setError("Please enter a bill name.");
      return;
    }

    if (!image) {
      setError("Please upload an image of the medical bill.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const { credentials } = await fetchAuthSession();

      const client = new BedrockRuntimeClient({
        region: "us-east-1", //
        credentials: credentials,
      });

      const base64Image = image ? await convertToBase64(image) : null;

      const input = {
        modelId: "anthropic.claude-3-5-sonnet-20240620-v1:0",
        contentType: "application/json",
        accept: "application/json",
        body: JSON.stringify({
          anthropic_version: "bedrock-2023-05-31",
          max_tokens: 1000,
          messages: [
            {
              role: "user",
              content: [
                { type: "text", text: short_prompt },
                base64Image
                  ? {
                      type: "image",
                      source: {
                        type: "base64",
                        media_type: "image/jpeg",
                        data: base64Image,
                      },
                    }
                  : null,
              ].filter(Boolean),
            },
          ],
        }),
      };

      const command = new InvokeModelCommand(input);
      const result = await client.send(command);

      const responseBody = JSON.parse(new TextDecoder().decode(result.body));
      console.log("Raw response from Claude:", responseBody);

      let parsedResponse;
      try {
        parsedResponse = JSON.parse(responseBody.content[0].text);
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
        throw new Error(
          "Failed to parse the response from Claude. The response might not be in the correct JSON format."
        );
      }

      if (Array.isArray(parsedResponse)) {
        setBillAnalysis(parsedResponse);
        setResponse(parsedResponse);
        await storeBillErrorsInDynamoDB(parsedResponse, billName);
      } else {
        throw new Error("Unexpected response format. Expected an array.");
      }
    } catch (error) {
      console.error("Error calling Claude:", error);
      setError(
        error.message ||
          "An error occurred while analyzing the image. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (file) => {
    setImage(file);

    try {
      const result = await uploadData({
        path: `public/${file.name}`,
        data: file,
        options: {
          contentType: file.type,
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
          },
        },
      });

      console.log("Path from Response: ", result.path);
    } catch (error) {
      console.error("Error uploading image: ", error);
      setError("Error uploading image. Please try again.");
    }
  };

  const storeBillErrorsInDynamoDB = async (billErrors, billName) => {
    const apiClient = generateClient();

    const createBillErrorsMutation = Object.values(mutations).find((mutation) =>
      mutation.includes("createBillErrors")
    );

    if (!createBillErrorsMutation) {
      console.error("createBillErrors mutation not found");
      return;
    }

    for (const error of billErrors) {
      try {
        const input = {
          cptCode: error["CPT Code"],
          cptCodeExplanation: error["CPT Code Explanation"],
          procedure: error["Procedure"],
          error: error["Error"],
          errorType: error["Error Type"],
          billName: billName,
        };

        console.log("Attempting to create BillError with input:", input);

        const result = await apiClient.graphql({
          query: createBillErrorsMutation,
          variables: { input },
        });

        console.log("Stored bill error in DynamoDB:", result);
      } catch (error) {
        console.error("Error storing bill error in DynamoDB:", error);
        if (error.errors) {
          error.errors.forEach((e) =>
            console.error("GraphQL error:", e.message)
          );
        }
      }
    }
  };

  return (
    <>
      <Flex align="center" justify="center">
        <Paper
          shadow="xs"
          radius="lg"
          withBorder
          p="xl"
          mx="lg"
          mt="sm"
          w="85%"
          h="40em"
        >
          <Title order={4}>Upload Your Bill</Title>
          <Text c="dimmed" size="md">
            Please upload the bill image here.
          </Text>
          <ScrollArea
            mt="lg"
            h={"30em"}
            scrollbarSize={10}
            scrollHideDelay={0}
            pb="lg"
          >
            <Stack>
              <LoadingOverlay visible={loading} overlayBlur={2} />
              <TextInput
                label="Bill Name"
                size="md"
                description="Name this bill."
                value={billName}
                onChange={(event) => setBillName(event.currentTarget.value)}
                required
                radius="md"
                style={{ marginBottom: "1rem" }}
              />
              <FileInput
                label="Upload Bill"
                description="Make sure the bill image is in PNG format."
                withAsterisk
                accept="image/*"
                onChange={handleImageUpload}
                style={{ marginBottom: "1rem" }}
                radius="md"
                size="md"
              />
              {!response && (
                <Button
                  onClick={callClaude}
                  mb="lg"
                  disabled={!image || !billName}
                >
                  Confirm Upload
                </Button>
              )}
              {image && (
                <Image
                  src={URL.createObjectURL(image)}
                  alt="Uploaded image"
                  maw={240}
                  mx="auto"
                  mb="lg"
                />
              )}
            </Stack>
          </ScrollArea>
        </Paper>
      </Flex>
    </>
  );
}

export default Step2;
