import React from "react";
import {
  Text,
  Stack,
  Paper,
  Title,
  Flex,
  ScrollArea,
  NumberInput,
  Textarea,
} from "@mantine/core";

function Step0() {
  return (
    <>
      <Flex align="center" justify="center">
        <Paper
          shadow="xs"
          radius="lg"
          withBorder
          p="xl"
          mx="lg"
          mt="sm"
          w="85%"
          h="40em"
        >
          <Title order={4}>Describe Your Case</Title>
          <Text c="dimmed" size="md">
            Please answer the following bill-specific questions to provide us
            more information about your bill and your concerns so that we could
            offer you the best help with it.
          </Text>

          <ScrollArea
            mt="lg"
            h={"30em"}
            scrollbarSize={10}
            scrollHideDelay={0}
            pb="lg"
          >
            <Stack>
              <NumberInput
                size="md"
                label="Total Charges"
                withAsterisk
                description="How much did they charge you in total on the bill?"
                prefix="$"
                min={0}
              />
              <Textarea
                size="md"
                label="Billing Scenario"
                description="Are their any other information or concerns you'd like to share with this bill?"
                h={"20em"}
              />
            </Stack>
          </ScrollArea>
        </Paper>
      </Flex>
    </>
  );
}

export default Step0;
