import React from "react";
import { Table, Paper, Title, Flex, ScrollArea } from "@mantine/core";

function Step3({ billAnalysis }) {
  return (
    <>
      <Flex align="center" justify="center">
        <Paper
          shadow="xs"
          radius="lg"
          withBorder
          p="xl"
          mx="lg"
          mt="sm"
          w="85%"
          h="40em"
        >
          <Title order={4}>Bill Analysis</Title>

          <ScrollArea
            mt="lg"
            h={"30em"}
            scrollbarSize={10}
            scrollHideDelay={0}
            pb="lg"
          >
            {billAnalysis && (
              <Table
                striped
                highlightOnHover
                withColumnBorders
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid black", width: "15%" }}>
                      CPT Code
                    </th>
                    <th style={{ border: "1px solid black", width: "20%" }}>
                      Procedure
                    </th>
                    <th style={{ border: "1px solid black", width: "35%" }}>
                      CPT Code Explanation
                    </th>
                    <th style={{ border: "1px solid black", width: "15%" }}>
                      Error
                    </th>
                    <th style={{ border: "1px solid black", width: "15%" }}>
                      Error Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {billAnalysis.flatMap((row, index) => {
                    const hasError = row["Error"] && row["Error"].trim() !== "";
                    const isDuplicateCharge =
                      row["Error"] &&
                      row["Error"].trim() === "Duplicate charge";

                    const rowElement = (
                      <tr
                        key={`${index}-${isDuplicateCharge ? 1 : 0}`}
                        style={{
                          backgroundColor: hasError ? "#ffcccc" : "#ccffcc",
                        }}
                      >
                        <td style={{ border: "1px solid black" }}>
                          {row["CPT Code"]}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {row["Procedure"]}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {row["CPT Code Explanation"]}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {row["Error"]}
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          {row["Error Type"]}
                        </td>
                      </tr>
                    );

                    // Return the row twice if it is a duplicate charge error
                    return isDuplicateCharge
                      ? [rowElement, rowElement]
                      : [rowElement];
                  })}
                </tbody>
              </Table>
            )}
          </ScrollArea>
        </Paper>
      </Flex>
    </>
  );
}

export default Step3;
