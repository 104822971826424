import React, { useState, useRef } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Button, Box, Text, TextInput, Group } from "@mantine/core";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const PDFEditor = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [annotations, setAnnotations] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newAnnotation, setNewAnnotation] = useState({ x: 0, y: 0, text: "" });
  const containerRef = useRef(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);
  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.2, 3));
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));

  const handleContainerClick = (event) => {
    if (!isAdding) return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setNewAnnotation({ ...newAnnotation, x, y });
  };

  const addAnnotation = () => {
    if (newAnnotation.text) {
      setAnnotations([...annotations, { ...newAnnotation, page: pageNumber }]);
      setNewAnnotation({ x: 0, y: 0, text: "" });
      setIsAdding(false);
    }
  };

  return (
    <Box>
      <Box
        ref={containerRef}
        style={{ position: "relative" }}
        onClick={handleContainerClick}
      >
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            scale={scale}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        {annotations
          .filter((ann) => ann.page === pageNumber)
          .map((ann, index) => (
            <Text
              key={index}
              style={{
                position: "absolute",
                left: `${ann.x}px`,
                top: `${ann.y}px`,
                background: "yellow",
                padding: "2px",
                borderRadius: "2px",
              }}
            >
              {ann.text}
            </Text>
          ))}
      </Box>
      <Text align="center">
        Page {pageNumber} of {numPages}
      </Text>
      <Group position="center" mt="sm" spacing="xs">
        <Button onClick={previousPage} disabled={pageNumber <= 1}>
          Previous
        </Button>
        <Button onClick={nextPage} disabled={pageNumber >= numPages}>
          Next
        </Button>
        <Button onClick={zoomIn}>Zoom In</Button>
        <Button onClick={zoomOut}>Zoom Out</Button>
        <Button onClick={() => setIsAdding(!isAdding)}>
          {isAdding ? "Cancel" : "Add Annotation"}
        </Button>
      </Group>
      {isAdding && (
        <Group position="center" mt="sm">
          <TextInput
            placeholder="Enter annotation text"
            value={newAnnotation.text}
            onChange={(event) =>
              setNewAnnotation({
                ...newAnnotation,
                text: event.currentTarget.value,
              })
            }
          />
          <Button onClick={addAnnotation}>Add</Button>
        </Group>
      )}
    </Box>
  );
};

export default PDFEditor;
