import React from "react";
import { Text, Paper, Title, Flex, ScrollArea } from "@mantine/core";

function Step0() {
  return (
    <>
      <Flex align="center" justify="center">
        <Paper
          shadow="xs"
          radius="lg"
          withBorder
          p="xl"
          mx="lg"
          mt="sm"
          w="85%"
          h="40em"
        >
          <Title order={4}>Review Personal Information</Title>
          <Text c="dimmed">
            Please confirm that the following personal information is correct
            and up-to-date, so that we could provide accurate analysis of your
            medical billing records.
          </Text>
          <ScrollArea
            h={"30em"}
            scrollbarSize={10}
            scrollHideDelay={0}
            pb="lg"
          ></ScrollArea>
        </Paper>
      </Flex>
    </>
  );
}

export default Step0;
