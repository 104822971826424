import React, { useState, useEffect } from "react";
import {
  Select,
  NumberInput,
  Button,
  Container,
  Title,
  Stack,
  Group,
  Table,
  Text,
  Box,
} from "@mantine/core";
import { generateClient } from "aws-amplify/api";
import {
  listUniqueStates,
  listHospitalsByState,
  getHospitalDetailsByName,
  getApplicationRequirementsByHospital,
} from "../graphql/queries";
import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import FederalPovertyLimitDetails from "./Fpl";
import PDFEditor from "./PDFEditor";

Amplify.configure(awsExports);
const client = generateClient();

const ParentComponent = () => {
  const [state, setState] = useState("All48");
  const [householdSize, setHouseholdSize] = useState(3);
  const [income, setIncome] = useState(25820);
  const [criteria, setCriteria] = useState(null);
  const [qualificationStatus, setQualificationStatus] = useState(null);
  const [incomeMultiple, setIncomeMultiple] = useState(0);
  const [states, setStates] = useState([]);
  const [hospitalNames, setHospitalNames] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [hospitalDetails, setHospitalDetails] = useState([]);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [showHospitalDropdown, setShowHospitalDropdown] = useState(false);
  const [applicationRequirements, setApplicationRequirements] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (qualificationStatus) {
      const fetchStates = async () => {
        try {
          const response = await client.graphql({
            query: listUniqueStates,
          });

          const { data, errors } = response;

          if (errors) {
            console.error("Errors:", errors);
          } else {
            const uniqueStates = [
              ...new Set(
                data.listHospitalPolicies.items.map(
                  (item) => item.hospitalState
                )
              ),
            ];
            setStates(uniqueStates);
            setShowStateDropdown(true);
          }
        } catch (error) {
          console.error("Error fetching states:", error);
        }
      };

      fetchStates();
    }
  }, [qualificationStatus]);

  useEffect(() => {
    const fetchHospitalNames = async () => {
      try {
        const response = await client.graphql({
          query: listHospitalsByState,
          variables: { state },
        });

        const { data, errors } = response;

        if (errors) {
          console.error("Errors:", errors);
        } else {
          const uniqueHospitalNames = [
            ...new Set(
              data.listHospitalPolicies.items.map((item) => item.hospitalName)
            ),
          ];
          setHospitalNames(uniqueHospitalNames);
          setShowHospitalDropdown(true);
        }
      } catch (error) {
        console.error("Error fetching hospital names:", error);
      }
    };

    if (state && showStateDropdown) {
      fetchHospitalNames();
    }
  }, [state, showStateDropdown]);

  const fetchHospitalDetails = async (hospitalName) => {
    try {
      const response = await client.graphql({
        query: getHospitalDetailsByName,
        variables: { hospitalName },
      });

      const { data, errors } = response;

      if (errors) {
        console.error("Errors:", errors);
      } else {
        setHospitalDetails(data.listHospitalPolicies.items);
      }

      // Fetch application requirements
      const appReqResponse = await client.graphql({
        query: getApplicationRequirementsByHospital,
        variables: { hospitalName },
      });

      if (appReqResponse.errors) {
        console.error(
          "Errors fetching application requirements:",
          appReqResponse.errors
        );
      } else {
        const appReq = appReqResponse.data.listApplicationRequirements.items[0];
        setApplicationRequirements(appReq);
        if (appReq && appReq.s3Url) {
          try {
            // Construct the public URL directly
            const bucketName =
              "medbillssvelte42572c26c30e42629ee0aa7315fab796e878e-sveltedev";
            const region = "us-west-2";
            const s3Url = `https://${bucketName}.s3.${region}.amazonaws.com/${appReq.s3Url}`;
            setPdfUrl(s3Url);
          } catch (error) {
            console.error("Error constructing S3 file URL:", error);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching hospital details:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCriteria({ state, householdSize, income });
    setShowHospitalDropdown(false);
    setShowStateDropdown(false);
    setHospitalDetails([]);
  };

  const handleQualificationStatus = (status, incomeMultiple) => {
    setQualificationStatus(status);
    setIncomeMultiple(incomeMultiple);
  };

  const handleHospitalChange = (hospitalName) => {
    setSelectedHospital(hospitalName);
    fetchHospitalDetails(hospitalName);
  };

  return (
    <Container>
      <Title order={2} align="center" mt="md" mb="lg">
        Federal Poverty Limit Lookup
      </Title>
      <form onSubmit={handleSubmit}>
        <Stack spacing="md">
          <Select
            label="State"
            value={state}
            onChange={setState}
            data={[
              { value: "All48", label: "All48" },
              { value: "Hawaii", label: "Hawaii" },
              { value: "Alaska", label: "Alaska" },
            ]}
            required
          />
          <NumberInput
            label="Household Size"
            value={householdSize}
            onChange={(value) => setHouseholdSize(value)}
            min={1}
            required
          />
          <NumberInput
            label="Income"
            value={income}
            onChange={(value) => setIncome(value)}
            min={0}
            step={1000}
            required
          />
          <Group position="center" mt="lg">
            <Button type="submit" variant="outline">
              Submit
            </Button>
          </Group>
        </Stack>
      </form>
      {criteria && (
        <FederalPovertyLimitDetails
          criteria={criteria}
          onQualificationStatus={handleQualificationStatus}
        />
      )}
      {showStateDropdown && states.length > 0 && (
        <Select
          label="Select State for Hospitals"
          placeholder="Select a state"
          data={states.map((state) => ({ value: state, label: state }))}
          onChange={setState}
        />
      )}
      {showHospitalDropdown && hospitalNames.length > 0 && (
        <Select
          label="Hospital Names"
          placeholder="Select a hospital"
          data={hospitalNames.map((name) => ({ value: name, label: name }))}
          onChange={handleHospitalChange}
        />
      )}
      {hospitalDetails.length > 0 ? (
        <div>
          <h2>Hospital Details</h2>
          <Table>
            <thead>
              <tr>
                <th>Hospital Name</th>
                <th>Hospital State</th>
                <th>Threshold Bottom</th>
                <th>Threshold Top</th>
                <th>Type of Care</th>
                <th>Percentage</th>
                <th>Percentage Description</th>
              </tr>
            </thead>
            <tbody>
              {hospitalDetails.map((detail) => {
                const thresholdBottom = parseFloat(detail.thresholdBottom);
                const thresholdTop = parseFloat(detail.thresholdTop);
                const isHighlighted =
                  incomeMultiple >= thresholdBottom &&
                  incomeMultiple <= thresholdTop;
                return (
                  <tr
                    key={detail.id}
                    style={{
                      backgroundColor: isHighlighted ? "yellow" : "transparent",
                    }}
                  >
                    <td>{detail.hospitalName}</td>
                    <td>{detail.hospitalState}</td>
                    <td>{detail.thresholdBottom}</td>
                    <td>{detail.thresholdTop}</td>
                    <td>{detail.typeOfCare}</td>
                    <td>{detail.percentage}</td>
                    <td>{detail.percentageDescription}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {applicationRequirements && (
            <Box mt="lg">
              <Title order={3}>Application Requirements</Title>
              <Text>{applicationRequirements.requirement}</Text>
              {pdfUrl && <PDFEditor pdfUrl={pdfUrl} />}
            </Box>
          )}
        </div>
      ) : (
        selectedHospital && <p>No policies found for the selected hospital.</p>
      )}
    </Container>
  );
};

export default ParentComponent;
