import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import Landing from "./pages/Landing";
import Signup from "./pages/Signup";
import SignupVerification from "./pages/Signup_verification";
import Signup_hospital from "./pages/Signup_hospital";
import Signup_insurance from "./pages/Signup_insurance";
import Signup_payment from "./pages/Signup_payment";
import Signup_servicedescription from "./pages/Signup_servicedescription";
import Signup_storage from "./pages/Signup_storage";
import Signup_services from "./pages/Signup_services";
import Home from "./pages/Home";
import NewBill from "./pages/NewBill";
import ParentFpl from "./pages/ParentFpl";
import { SignupProvider } from "./pages/Signup_context";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/landing" element={<Landing />} />
        <Route
          path="/*"
          element={
            <Authenticator>
              {({ signOut, user }) => (
                <Routes>
                  <Route path="/home" element={<Home signOut={signOut} />} />
                  <Route path="/new-bill" element={<NewBill />} />
                  <Route path="/parentfpl" element={<ParentFpl />} />

                  {/* Wrap each signup-related Route in SignupProvider individually */}
                  <Route
                    path="/signup"
                    element={
                      <SignupProvider>
                        <Signup />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_verification"
                    element={
                      <SignupProvider>
                        <SignupVerification />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_hospital"
                    element={
                      <SignupProvider>
                        <Signup_hospital />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_insurance"
                    element={
                      <SignupProvider>
                        <Signup_insurance />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_payment"
                    element={
                      <SignupProvider>
                        <Signup_payment />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_servicedescription"
                    element={
                      <SignupProvider>
                        <Signup_servicedescription />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_storage"
                    element={
                      <SignupProvider>
                        <Signup_storage />
                      </SignupProvider>
                    }
                  />
                  <Route
                    path="/signup_services"
                    element={
                      <SignupProvider>
                        <Signup_services />
                      </SignupProvider>
                    }
                  />
                </Routes>
              )}
            </Authenticator>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
