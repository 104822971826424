import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { getFederalPovertyLimitsByCriteria } from "../graphql/queries"; // Ensure this is the correct path to your queries
import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);
const client = generateClient();

const FederalPovertyLimitDetails = ({ criteria, onQualificationStatus }) => {
  const [limits, setLimits] = useState([]);
  const [qualified, setQualified] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { state, householdSize, income } = criteria;
      const year = 2024;

      console.log("Fetching data with criteria:", {
        year,
        state,
        householdSize,
        income,
      });

      if (!state || !householdSize || !income) {
        console.error("Error: One or more criteria are null or undefined.");
        setError("All criteria are required to fetch data.");
        setLoading(false);
        return;
      }

      try {
        const response = await client.graphql({
          query: getFederalPovertyLimitsByCriteria,
          variables: { year, state, householdSize, income },
        });

        console.log("Full API Response:", response);

        const { data, errors } = response;

        if (errors) {
          console.error("Errors:", errors);
          setError(errors);
        } else {
          const items = data.listFederalPovertyLimits.items;
          console.log("Fetched items:", items);
          setLimits(items);

          // Set qualification status based on fetched items
          const status = items.length > 0 ? "Not Qualified" : "Qualified";
          setQualified(status);

          // Calculate the income multiple as a percentage
          const incomeMultiple =
            items.length > 0
              ? ((criteria.income / items[0].income) * 100).toFixed(2)
              : 0;

          // Call the callback function to update the parent component
          onQualificationStatus(status, parseFloat(incomeMultiple));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [criteria, onQualificationStatus]);

  const renderMultipleOfIncome = (item) => {
    const multiple = ((criteria.income / item.income) * 100).toFixed(1);
    return <p>Multiple of income: {multiple}%</p>;
  };

  if (loading) return <p>Loading data...</p>;
  if (error) return <p>Error loading data: {JSON.stringify(error)}</p>;

  return (
    <div>
      <p>{qualified} Under Poverty Limit</p>
      {limits.length > 0 ? (
        limits.map((limit) => (
          <div key={limit.id}>
            <p>
              ID: {limit.id} | Year: {limit.year} | State: {limit.state} |
              Household Size: {limit.householdSize} | Income: {limit.income} |
              User ID: {limit.userId}
            </p>
            {qualified === "Not Qualified" && (
              <>{renderMultipleOfIncome(limit)}</>
            )}
          </div>
        ))
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default FederalPovertyLimitDetails;

// uses the following schema
// src/graphql/schema.graphql
// export const getFederalPovertyLimitsByCriteria = /* GraphQL */ `
//   query GetFederalPovertyLimitsByCriteria($year: Int!, $state: String!, $householdSize: Int!, $income: Int!) {
//     listFederalPovertyLimits(filter: {
//       year: { eq: $year },
//       state: { eq: $state },
//       householdSize: { eq: $householdSize },
//       income: { lt: $income }
//     }) {
//       items {
//         id
//         year
//         state
//         householdSize
//         income
//         userId
//       }
//     }
//   }
// `;
