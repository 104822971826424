import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Stepper, Button, Group, Flex } from "@mantine/core";
import Step1 from "./feature1/Step1";
import Step0 from "./feature1/Step0";
import Step2 from "./feature1/Step2";
import Step3 from "./feature1/Step3";

export default function NewBill() {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [billAnalysis, setBillAnalysis] = useState(null);

  const nextStep = () => {
    if (active === 4) {
      navigate("../home");
    }
    setActive((current) => (current < 4 ? current + 1 : current));
  };

  const prevStep = () => {
    if (active === 0) {
      navigate("../home");
    }
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <>
      <Flex mt="sm" pt="xs" align="center" justify="center">
        <Stepper
          mx="lg"
          pt="xs"
          pb="xs"
          active={active}
          onStepClick={setActive}
          w="70%"
        >
          <Stepper.Step label="Review Info" description="Step 0">
            <Step0 />
          </Stepper.Step>
          <Stepper.Step label="Describe Case" description="Step 1">
            <Step1 />
          </Stepper.Step>
          <Stepper.Step label="Upload Bill" description="Step 2">
            <Step2 setBillAnalysis={setBillAnalysis} />
          </Stepper.Step>
          <Stepper.Step label="Get Results" description="Step 3">
            <Step3 billAnalysis={billAnalysis} />
          </Stepper.Step>
          <Stepper.Completed>
            Next Steps, Consider applying for financial assistance ...{" "}
          </Stepper.Completed>
        </Stepper>
      </Flex>
      <Group justify="center" mt="xs">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button onClick={nextStep}>Next</Button>
      </Group>
    </>
  );
}
