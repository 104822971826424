export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      userId
      hospital
      insurance
      age
      first_name
      last_name
      payment
      serviceDescription
      s3ImageKey
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $id: ID
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        hospital
        insurance
        age
        first_name
        last_name
        payment
        serviceDescription
        s3ImageKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserImage = /* GraphQL */ `
  query GetUserImage($id: ID!) {
    getUserImage(id: $id) {
      id
      userProfileId
      s3ImageKey
      userId
      userProfile {
        id
        userId
        hospital
        insurance
        age
        first_name
        last_name
        payment
        serviceDescription
        s3ImageKey
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserImages = /* GraphQL */ `
  query ListUserImages(
    $id: ID
    $filter: ModelUserImageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserImages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userProfileId
        s3ImageKey
        userId
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFederalPovertyLimits = /* GraphQL */ `
  query GetFederalPovertyLimits($id: ID!) {
    getFederalPovertyLimits(id: $id) {
      id
      year
      state
      householdSize
      income
      userId
      __typename
    }
  }
`;

export const listUniqueStates = /* GraphQL */ `
  query ListUniqueStates {
    listHospitalPolicies {
      items {
        hospitalState
      }
    }
  }
`;

// Get hospitals by state
export const listHospitalsByState = /* GraphQL */ `
  query ListHospitalsByState($state: String!) {
    listHospitalPolicies(filter: { hospitalState: { eq: $state } }) {
      items {
        hospitalName
        hospitalState
      }
    }
  }
`;

// Get hospital details by name
export const getHospitalDetailsByName = /* GraphQL */ `
  query GetHospitalDetailsByName($hospitalName: String!) {
    listHospitalPolicies(filter: { hospitalName: { eq: $hospitalName } }) {
      items {
        id
        hospitalName
        hospitalState
        thresholdBottom
        thresholdTop
        typeOfCare
        percentage
        percentageDescription
      }
    }
  }
`;

// src/graphql/queries.js
export const getFederalPovertyLimitsByCriteria = /* GraphQL */ `
  query GetFederalPovertyLimitsByCriteria(
    $year: Int!
    $state: String!
    $householdSize: Int!
    $income: Int!
  ) {
    listFederalPovertyLimits(
      filter: {
        year: { eq: $year }
        state: { eq: $state }
        householdSize: { eq: $householdSize }
        income: { lt: $income }
      }
    ) {
      items {
        id
        year
        state
        householdSize
        income
        userId
      }
    }
  }
`;

export const listFederalPovertyLimits = /* GraphQL */ `
  query ListFederalPovertyLimits(
    $id: ID
    $filter: ModelFederalPovertyLimitsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFederalPovertyLimits(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        year
        state
        householdSize
        income
        createdAt
        updatedAt
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHospitalPolicies = /* GraphQL */ `
  query GetHospitalPolicies($id: ID!) {
    getHospitalPolicies(id: $id) {
      id
      hospitalName
      hospitalState
      thresholdBottom
      thresholdTop
      typeOfCare
      percentage
      percentageDescription
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const listHospitalPoliciesNames = /* GraphQL */ `
  query ListHospitalPoliciesNames {
    listHospitalPolicies {
      items {
        hospitalName
      }
    }
  }
`;

export const listHospitalPolicies = /* GraphQL */ `
  query ListHospitalPolicies(
    $filter: ModelHospitalPoliciesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHospitalPolicies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hospitalName
        hospitalState
        thresholdBottom
        thresholdTop
        typeOfCare
        percentage
        percentageDescription
        createdAt
        updatedAt
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationRequirements = /* GraphQL */ `
  query GetApplicationRequirements($id: ID!) {
    getApplicationRequirements(id: $id) {
      id
      hospitalName
      requirement
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const listApplicationRequirements = /* GraphQL */ `
  query ListApplicationRequirements(
    $id: ID
    $filter: ModelApplicationRequirementsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplicationRequirements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hospitalName
        requirement
        createdAt
        updatedAt
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userProfilesByUserIdAndId = /* GraphQL */ `
  query UserProfilesByUserIdAndId(
    $userId: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProfilesByUserIdAndId(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        hospital
        insurance
        age
        first_name
        last_name
        payment
        serviceDescription
        s3ImageKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userImagesByUserProfileIdAndS3ImageKey = /* GraphQL */ `
  query UserImagesByUserProfileIdAndS3ImageKey(
    $userProfileId: ID!
    $s3ImageKey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userImagesByUserProfileIdAndS3ImageKey(
      userProfileId: $userProfileId
      s3ImageKey: $s3ImageKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProfileId
        s3ImageKey
        userId
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBillErrors = /* GraphQL */ `
  query GetBillErrors($id: ID!) {
    getBillErrors(id: $id) {
      id
      cptCode
      procedure
      error
      errorType
      billName
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const listBillErrors = /* GraphQL */ `
  query ListBillErrors(
    $filter: ModelBillErrorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cptCode
        procedure
        error
        errorType
        billName
        createdAt
        updatedAt
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getApplicationRequirementsByHospital = /* GraphQL */ `
  query GetApplicationRequirementsByHospital($hospitalName: String!) {
    listApplicationRequirements(
      filter: { hospitalName: { eq: $hospitalName } }
    ) {
      items {
        id
        hospitalName
        requirement
        s3Url
      }
    }
  }
`;
